/*Обнуление*/

*{
	padding: 0;
	margin: 0;
	border: 0;
}
*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
	overflow-x: hidden;
	font-family: 'Helvetica';
	font-style: normal;
	background: rgba(19, 27, 53, 1);
	/* overflow-x: hidden; */
	height:fit-content;
	
  }

  
@font-face {
	font-family:Montserrat;
	src: url(../src/fonts/Montserrat.ttf);
}
@font-face{
	font-family: proximanova;
	src: url(../src/fonts/proximanova_regular.ttf);
}
@font-face{
	font-family: Roboto;
	src: url(../src/fonts/Roboto-Regular.ttf);
}
@font-face{
	font-family: Rlight;
	src: url(../src/fonts/Roboto-Light.ttf);
}
  /* Navbar */

  
  .navbar {
	z-index: 3;
	vertical-align: middle;
	justify-content:right;
	align-items: center;
	/* max-width: 1260px; */
	height: 5.5vw;
	/* margin: 0 auto 0px auto; */
	/* position: relative; */
	background: rgba(19, 27, 53, 1);

  }

  @keyframes show_head{
  0%{
    opacity: 0;
	top:-1vw;

  }
  100%{
    opacity: 1;
    top: 0;
  }
}

  .logo img{
	width: 3.5%;
	float: left;
	margin: 1% 0 0 4%;
	

	/* margin-top: 10px; */
  }
  .logo{
	display: none;
  }
  .logosh{
	display: block;
  }
  
  .menu {
	padding-top: 1.8vw;
	padding-right: 4.5vw;

	align-items: center;
	display:flex;
	justify-content: right;
  }
  
  .menu li {
	font-size: 1.5vw;
	font-weight: lighter;
	cursor: pointer;
	margin-left: 1.7%;
	/* white-space: nowrap; */
	display: flex;
	justify-content: center;
	align-items: center;
	/* padding:20px; */
  }

  .menu li a:hover{
	
	transition: 0.3s;
	color: white;
  }

  .menu span{
	padding-top: 0.3vw;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 3vw;
	margin-left: 3.5vw;
	font-size: 1.1vw;
	font-weight: lighter;
	color: rgba(255, 255, 255, 0.72);
  }

  .menu li a{
	text-decoration: none;
	color: rgba(255, 255, 255, 0.72);
  }
  
  /* .menu li:hover {
	border-bottom: 3px solid #FFFFFF;
  }
  
  .menu-active {
	border-bottom: 3px solid #FFFFFF;
  } */
  
  /* .burger-menu {
	display: none;
	cursor: pointer;
  }
  
  .burger-menu__btn img{
	width: 25px;
  }
   */
  
  /* Navbar adaptive */
/*   
  
  @media screen and (max-width: 1140px){
  
	.menu{
	  display: none;
	}
  
	.burger-menu {
	  display: block;
	}
  
  } */
  

  /* main page */

.mainslide{
	position: relative;
}


.mountain_main{
	z-index: 0;
	width:100%;
}
.mainslide h1{
	font-family:Montserrat;
	color: white;
	font-weight: bold;
	font-size: 3vw;
	position: absolute;
	top: 15%;
	left: 15%;
	width: 43%;
	text-align: left;
}

.mainslide span{
	font-family:Montserrat;
	color: white;
	font-weight: bold;
	font-size: 3vw;
	position: absolute;
	top: 22.5%;
	left: 20%;
	width: 40%;
	text-align: right;
}
.mainslide h4{
	white-space:pre-wrap;
	width: 34%;
	font-family:proximanova;
	color: white;
	font-style: normal;
	font-weight:normal;
	font-size: 2vw;
	line-height: 3vw;
	position: absolute;
	top: 35%;
	left: 15%;
}

.mainslide a{
	color: #FFFFFF;
	text-decoration: none;
	padding: 0.5vw;
	font-family:proximanova;
	font-size: 1.2vw;
	position: absolute;
	top: 67%;
	left: 34%;
/* filter: blur(9.5px); */
	border-radius: 0.4vw;
	background: rgba(217, 217, 217, 0.22);
	border: 2px solid #FFFFFF;
	filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.638));
}


/* aboutslide */


.aboutnames{
	display:flex;
}



.aboutslide img{
	display: block;
	margin-left: 22vw;
	margin-top: 13vw;
	z-index: 2;
	width: 10vw;
	filter: drop-shadow(0 0 200px rgba(255, 255, 255, 0.638));
}


.namecompany2{
	text-align: center;
	align-items: center;
	display:flex;
	justify-content: center;
	margin-top: 12vw;
	margin-left: 5vw;
	padding: 2vw;
	width: 35vw;
	height: 9vw;
	background: linear-gradient(90.9deg, #243363 -1.42%, rgba(36, 51, 99, 0) 103.67%);
	border-radius: 48px;
	z-index: 3;
	box-shadow: 7px 9px 10px rgba(0, 0, 0, 0.335);
}
.namecompany2 span{
	width: 18vw;
	margin-left: 2vw;
	font-family: Roboto;
	white-space:pre-wrap;
	font-weight:lighter;
	font-size: 1.7vw;
	line-height: 2vw;
	color: #FFFFFF;
}
/* .chop{
	width: 15vw;
	font-family: Roboto;
	white-space:pre-wrap;
	font-weight: bolder;
	font-size: 1.7vw;
	line-height: 2vw;
	color: #FFFFFF;

} */

.palka{
	width: 0;
	height: 7vw;
	margin-left: 2vw;
	border: 1px solid #566491;
}
/* .ooo{
	width: 15vw;
	margin-left: 2vw;
	font-family: Roboto;
	white-space:pre-wrap;
	font-weight:bolder;
	font-size: 1.7vw;
	line-height: 2vw;
	color: #FFFFFF;
} */

.aboutslide{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 23px;
	color: rgba(162, 185, 216, 0.63);
}

.aboutslide h3{
	/* left:40%; */
	position:relative;
	width: 37vw;
	font-family: Roboto;
	/* font-style: normal; */
	font-weight:lighter;
	font-size: 1.3vw;
	line-height: 1.5vw;
	margin-left: 6vw;
	margin-top: 1vw;
	color: rgba(162, 185, 216, 0.63);
}
.advantages{
	margin-top: 10vw;
	display: flex;
	justify-content: center;
}

.advantage{
	text-align: center;
	width: 20vw;
	align-items: center;
	justify-content: center;
	
}
.advantage i{
	font-size: 6vw;
	color: rgba(255, 255, 255, 0.856);
}

.advantage h4{
	font-family: 'Roboto';
	font-style: normal;
	font-size: 1.5vw;
	line-height: 1.4vw;

	color: rgba(255, 255, 255, 0.97);
	margin-top: 2vw;
	text-align: left;

	/* margin-right: 10vw; */
}


.advantage h6{
	font-family: 'Roboto';
	font-weight: lighter;
	font-size: 1vw;
	line-height:1.15vw;
	
	color: rgba(255, 255, 255, 0.6);
	margin-top: 1vw;
	text-align: left;
	
	/* margin-right: 10vw; */
}

.palka2{
	margin-top: 3.5vw;
	height: 12vw;
	margin-left: 3vw;
	margin-right: 3vw;

}
.fa-user-tie{
	padding-right: 1vw;
}

.nameC{
	white-space: nowrap;
	font-weight: bold;
	font-family: 'Times New Roman', Times, serif;
}
/* achievmens */

.achievementslide{
	position: relative;
	margin-top: 10vw;
	display: flex;
	justify-content: center;

}
.CloudShield{
	display: none;
	z-index: 0;
	position: absolute;
	right: 0%;
	top:-40%;
	width: 33%;
	animation: CloudShield 1.5s ease-in-out;
}
.CloudShield2{
	display: none;
	z-index: 0;
	position: absolute;
	left: 0%;
	bottom:-48%;
	width: 43%;
	animation: CloudShield2 1.5s ease-in-out;
}


@keyframes CloudShield{
	0%{
		opacity: 0;
		right: -20%;
	}
	100%{
		opacity: 1;
		right: 0%;
	}
}
@keyframes CloudShield2{
	0%{
		opacity: 0;
		left: -20%;
	}
	100%{
		opacity: 1;
		left: 0%;
	}
}

.description{
	width: min-content;
	font-family: 'Roboto';
	font-style: normal;
	/* font-weight: lighter; */
	font-size: 1.2vw;
	line-height: 1.6vw;

	color: rgba(255, 255, 255, 0.8);
}
.dis1{
	/* flex- */
	text-align: left;
}
.dis2{
	float: right;
	text-align: right;
}
.description h3{
	display: none;
	padding: 1.5vw;
	background: rgba(0, 0, 0, 0.2);
	box-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
	margin-bottom:5vw;
	margin-top: 1vw;
}

.shield{
height: 36.8vw;
}

.shield img{
	width: 23vw;
	margin-top: 2.8vw;
}
.qwe{
	width: 30vw;
}
.qq{
	margin-left: 12vw;
}
.ewq{
	width: 18vw;
}

font{
	font-weight: 900;
	font-size:1.7vw;
	color:rgb(215, 225, 253);
	text-shadow: 0 0 5px rgb(215, 225, 253);
}


@keyframes left_table{
	0%{
	  opacity: 0;
	  margin-bottom: 0vw;
  
	}
	100%{
	  opacity: 1;
	  margin-bottom: 5vw;

	  
	}
}
/* .qzz{
animation: left_table 4s;
} */

  /* services */
.uslugi{
	color: #FFFFFF;
	font-family: 'Roboto';
	font-style: normal;
	font-size: 1.7vw;
	padding-top: 7vw;
	margin: 5vw 0 4vw 12vw;
}

.CardServ h4{
	transition: ease 0.5s;
	z-index: 3;

	font-family: 'Roboto';
	font-style: normal;
	font-weight:bolder;
	font-size: 1.4vw;
	line-height: 1.5vw;
	padding-right: 5vw;
	white-space:pre-wrap;
	/* padding-right: 1.5vw; */
	color: #FFFFFF;
}


.Cards{
	display: flex;
	justify-content: center;
}
.CardServ{
	cursor: pointer;
	transition: ease 0.5s;
	position: relative;
	margin-left: 1.5vw;
	margin-right: 1.5vw;
	padding-left: 1.7vw;
	width: 17vw;
	height: 17vw;
	background: #090E1B;
	box-shadow: 10px 8px 9px rgba(0, 0, 0, 0.4);
	border-radius: 24px;
}

.servicesblock{
	display: flex;
	justify-content: center;
}
.servicesblock h3{
	text-align: center;
	font-weight: bolder;
font-size: 1.5vw;
margin: 1vw;
padding: 0.4vw;
color: #0C2740;
background: #D9D9D9;
border: 0.25vw solid #4B7FB2;
box-shadow: 0 0.5vw 0.5vw rgba(0, 0, 0, 0.25);
border-radius: 1vw;
}
.servicesblockvac{
	display: inline;
	margin-bottom: 5vw;
}
.servicesblockvac i{
	font-size: 2vw;
	padding: 0.5vw;
}
.servicesblockvac h3{
	margin-left: 14vw;
	width: 30vw;
	font-size: 1.6vw;
	text-align: left;

}

.services button {
	transition: ease 0.5s;
	bottom: 7%;
	right: 7%;
	position: absolute;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 1.25vw;
	color: #3680C0;
	background: #090E1B;
	cursor: pointer;
	padding: 1.06vw;
}

.services i{
	transition: ease 0.5s;
	padding-top: 1.5vw;
	padding-bottom: 1.5vw;
	color: rgba(113, 128, 167, 1);
	font-size: 5vw;
}

.CardServ:hover{
	background: #7180A7;
	transition: ease 0.5s;
}
.CardServ:hover> button{
	transition: ease 0.5s;

	color: #0C1E2E;
	background: #7180A7;
}
.CardServ:hover> i{
	transition: ease 0.5s;

	color: #0C1E2E;
}
.CardServ:hover> h4{
	transition: ease 0.5s;
	color: #0C1E2E;
}

#Pult,#Fiz,#Econom,#Teh{
	margin-top: 2vw;
	font-family: 'Roboto';
font-style: normal;
	padding: 2vw 4vw 2vw;
	margin-left: 11.5vw;
	justify-content: center;
	display: none;
	width: 78vw;
	background: #7180A7;
border: 6px solid rgba(0, 0, 0, 0.3);
border-radius: 1.5vw;
}

.services h5{
	text-indent: 2vw;
	font-weight:lighter;
font-size: 1.8vw;
line-height: 1.9vw;
margin-top: 1vw;
color: #0B2339;
}
.buttonet{
	margin-top: 1vw;
	display:flex;
	justify-content: center;
	background-color:#152346;
	width: 20vw;
	margin-left: 24.5vw;
	border-radius: 1vw;
}
#Teh a{
	margin-top: 1vw;
	display:flex;
	justify-content: center;
	background-color:#152346;
	width: 20vw;
	margin-left: 24.5vw;
	border-radius: 1vw;
	text-decoration: none;

font-size: 1.8vw;
line-height: 1.9vw;
	color:white;
	padding: 1vw;
}
#Econom a{
	margin-top: 1vw;
	display:flex;
	justify-content: center;
	background-color:#152346;
	width: 20vw;
	margin-left: 24.5vw;
	border-radius: 1vw;
	text-decoration: none;

font-size: 1.8vw;
line-height: 1.9vw;
	color:white;
	padding: 1vw;
}
.services br{
	text-indent: 2vw;

}

.ServicePrice{
	justify-content: center;
	display: flex;
	
}
.PriceBlock{
	background-color: #FFFFFF;
	text-align: center;
	width: 15vw;
	height: 15vw;
	margin-left: 1vw;
	margin-right: 1vw;
}
.PriceName{
	height: 4vw;
	padding: 0.7vw;
	font-weight:bolder;
font-size: 1.3vw;
line-height: 1.5vw;
text-align: center;

color: #D9D9D9;
	background-color: #221D43;
}
.Price{
	margin-top: 2vw;
	margin-bottom: 2.3vw;
	font-size:1.5vw;
}

.PriceBlock a{
	font-weight: lighter;
font-size: 1.5vw;
text-decoration: none;
color: #221D43;
	padding: 0.4vw;
	border: 1px solid #221D43;

}
.PriceTriangle{
	width: 0;
    height: 0;
    border-top: 2vw solid #221D43;
    border-left: 7.5vw solid transparent;
    border-right: 7.5vw solid transparent;
}


.Rates{
	margin-top: 2vw;
	margin-bottom: 2vw;
color: #221D43;
	display: flex;
	justify-content: center;
}

.services h2{
	display: flex;
	justify-content: center;
	font-weight:bolder;
font-size: 1.8vw;
line-height: 3vw;
color: #221D43;

}
.Dop {
margin-top: 1vw;
font-weight: lighter;
font-size: 1.5vw;
line-height: 1vw;
padding-left: 1vw;
padding-bottom: 1vw;

color: #0C2740;
}
.fizblock h3{
	width: 40vw;
}
.tehblock{
	display: inline;
}
.tehblock h3{
	padding: 0.8vw;
	text-align:left;
	width: 100%;
	font-size: 1.3vw;
	height: fit-content;
}
.economblock{
	display: flex;
	justify-content: center;
}
.ServicePricefiz .PriceBlock{

	width: 22vw;
	margin-left: 2vw;
	margin-right: 2vw;
}
.PriceTrianglefiz{
    border-top: 2vw solid #221D43;
    border-left: 11vw solid transparent;
    border-right: 11vw solid transparent;
}


@keyframes Show_Des{
	0%{
		opacity: 0;
		height: 0%;
	}
	100%{
		opacity: 1;
		height: 100%;
	}
}
/* @keyframes Hide_Des{
	0%{
		opacity: 0;
		height: 100%;
	}
	100%{
		opacity: 1;
		height: 0%;
		display: none;
	}
}  */


	/* stages */

.Cooperation{
	margin-top: 8vw;
}


.CooperationName{
	justify-content: center;
	display: flex;
}

.CooperationName span{
	
	font-family: 'Roboto';
	font-style: normal;
	font-weight:bolder;
	font-size: 2vw;
	color: #FFFFFF;
}

.palkaCoop{
	border: 1px solid #ffffffb8;

	width: 10vw;
	height: 0;
	margin-right: 2vw;
	margin-left: 2vw;
	margin-top: 1vw;
}
.palkastages{
	border: 1px solid #ffffffb8;
	margin-top: 6.5vw;
	margin-left: 0vw;

	width: 5vw;
	height: 0;

}
.stages{
	margin-top: 3vw;
	justify-content: center;
	display: flex;
}
.stage{
	position: relative;
	justify-content: center;
	text-align: center;
	width: 10.5vw;
	height: 14vw;
}

.chel{
	width: 10vw;
}
.handsh{
	margin-top: 0.8vw;
	width: 8vw;
}
.list{
	width: 5.4vw;
}
.search{
	width: 7vw;
}
.phone{
	width: 6.3vw;
}

.stage h1{
	color: #FFFFFF;
	font-size: 3vw;
	margin-bottom: 3vw;
}
.stage h6{
	top:107%;
	position: absolute;
	white-space: pre-wrap;
	font-family: 'Roboto';
	font-style: normal;
	font-weight:lighter;
	font-size: 1vw;
	line-height: 1.1vw;
	text-align: center;
	color: #FFFFFF;
}

	/* Slider */
.Carousel{
	width: 150%;
}

.license{
	margin-top: 10vw;
display: flex;
}

.license h4{
	color: #FFFFFF;
	font-family: 'Roboto';
	font-style: normal;
	font-size: 1.7vw;
	padding-top: 7vw;
	margin: 0vw 0 4vw 14vw;
}

.licensevac{
display: flex;
}

.licensevac h4{
	color: #FFFFFF;
	font-family: 'Roboto';
	font-style: normal;
	font-size: 1.7vw;
	padding-top: 7vw;
	margin: 0vw 0 2vw 14vw;
}
.servicesblockvac p{
	text-indent: 3vw;
}

.license h5{
	margin-left: 14vw;
	padding-right: 7vw;
	font-family: 'Roboto';
	font-style: normal;
	font-weight:lighter;
	font-size: 1.8vw;
	line-height: 2.3vw;
	/* or 167% */

	color: rgba(255, 255, 255, 0.8);
}
.license{
	margin-right: 2vw;
}
.CarouselContainer{
	margin-top: 7vw;
	width: 190%;
	/* border: #090E1B 3px solid; */
	overflow: hidden;
}
.CarouselSlides{
	width: 100%;
	display: flex;
}
  .CarouselSlides img{
	margin-left:20%;
	margin-right: 20%;
	width: 60%;
	height: 40%;
}

#prevBtn,#nextBtn{
	cursor: pointer;
	background-color: #ffffff00;
	z-index: 2;
	margin-top: 14vw;
	justify-content: center;
	width: 20vw;
	height: 10vw;
}
#prevBtn,#nextBtn i{
	font-size: 4vw;

	color: #FFFFFF;
}








/* footer */

.footer{
	position: relative;
	display: flex;
	padding: 2vw;
	color: white;
	background-color: #000000;

}
.stolb{
	margin-left: 3vw;
}

.footer h5{
	font-family: Roboto;
	font-weight: bolder;
	font-size: 1.2vw;
	line-height: 2vw;
	color: rgb(255, 255, 255);
}
.f3{
	font-family: Roboto;
	font-weight: bold;
	line-height: 1.7vw;
	color: rgb(255, 255, 255);
	margin-bottom: 1vw;
	font-size: 1.1vw;
}

.footer a{
	text-decoration: none;
	font-weight: light;
	font-size: 0.8vw;
	white-space: nowrap;
	line-height: 1vw;
	color: rgba(255, 255, 255, 0.4);
}

.footer a:hover{
	transition: all 0.2s ease;
color: rgba(255, 255, 255, 0.849);
}
.footer h3{
	font-size: 1.1vw;
	margin-bottom: 0.5vw;
	font-weight: bold;
}
.footername{
	padding-top: 1vw;
	padding-left: 1vw;
}
.footername h2{
	font-size: 2vw;
}
.footername h3{
	font-size: 1vw;
}
.footername h4{
	font-size: 1vw;
}
.emblema{
	margin-left:5vw;
}
.emblema h6{
	font-size: 0.8vw;
}
.olympic{
	display:flex;
}
.emblema img{
	width: 5vw;
	margin-bottom: 1vw;
}
.stolb i{
	padding-right: 1vw;
}
.passage{
	bottom: 11%;
	right: 1%;
	position: absolute;
}
.passageimg{
	width: 22vw;
	height: 17vw;
}

/* partners reg */

.PartnersLetterText{
	margin-top: 20%;
	font-family: Roboto;
	font-size:2vw;
	font-weight:bolder;
	width: 100%;
	display: flex;
	justify-content: center;
	color: #ffffff;
	position: relative;
  }

  .PartnersLetterText img{
	display: none;
	width: 50%;
	top:-700%;
	right: 0%;
	position: absolute;
	animation: CloudShield 1.5s ease-in-out;

  } 
  
  
  .PartnersLetter{
	width: 63%;
	position:relative;
	margin: 2vw auto;
	padding: 2vw;
	min-height: 28vw;
	/* min-width: 1260px; */
	border: 2px solid white; 
	border-radius: 2vw;

	
	/* display: flex;
	align-items: center;
	justify-content: center; */
	/*  opacity: 50%; */
  } 
  .PartnersLetter1,.PartnersLetter2,.PartnersLetter3,.PartnersLetter4,.PartnersLetter5,.PartnersLetter6,.PartnersLetter7 {
	transform: translate(-50%, 0%);
	left: 50%;
	position: absolute;
  }
  .PartnersLetter1{
	width: 90%;
  }
  .PartnersLetter2{
	width: 88%;
  }
  .PartnersLetter3{
	width: 85%;
  }
  .PartnersLetter4{
	width: 81%;
  }
  .PartnersLetter5{
	width: 76%;
  }
  .PartnersLetter6{
	width: 70%;
  }
  .PartnersLetter7{
	width: 63%;
  }
  
  
  
  /* partners letter adaptive */
  
  @media screen and (max-width: 1260px) {
	.PartnersLetter{
	  width: 90%;
	}
  
	.PartnersLetter1{
	  width: 90%;
	  display: none;
	}
	.PartnersLetter2{
	  width: 90%;
	  display: none;
	}
	.PartnersLetter3{
	  width: 90%;
	  display: none;
	}
	.PartnersLetter4{
	  width: 90%;
	  display: none;
	}
	.PartnersLetter5{
	  width: 90%;
	  display: none;
	}
	.PartnersLetter6{
	  width: 90%;
	  display: none;
	}
	.PartnersLetter7{
	  width: 90%;
	  display: none;
	}
  }
  
  /* partners letter form */
  
  .PartnersLetter form{
	padding-left: 1vw;
	padding-right: 1vw;
  }
  .PartnersLetter input{
	font-size:1vw;
	/* padding: 5px; */
	margin: 1vw;
	color: #fff;
	width: 100%;
	border-bottom: 1px solid #fff;
	background-color: #ffffff00;
  }
  .PartnersLetter label{
	font-size:1vw;
	color: #ffffff;
  }
  
  .partners-form {
	width: 100%;
  
  }
  
  .partners-form input{
	width: 100%;
	white-space: pre-wrap;
  }
  
  
  /* Partners button */
  
  .PartnersButton{
	cursor: pointer;
	font-size: 1.4vw;
	height: 4.5vw;
	width: 70%;
	position:relative;
	transform:translate(-50%, 0%);
	left: 50%;
	margin: 0px auto 0px auto;
	background-color: #ffffff00;
	color: rgb(255, 255, 255);
	border: 2px solid white; 
	border-radius: 1.6vw;
  }
  
 /* calculator */
 .calc{
	display: none;
 }
 .calc span{
	margin-top: 6vw;
	margin-bottom: 3vw;
 }
 .calc1{
	margin-top: 6vw;
	font-size: 2vw;
	display: flex;
	justify-content: center;
	border-radius: 1vw;

 }
 .calclabel{
	width: fit-content;
	height:fit-content;
	background-color: white;
	border-radius: 1vw;
}
.calclabel h1{
	margin-top: 1.2vw;
	font-size: 2vw;
	display: flex;
	justify-content: center;
}
 .CalcPult,.CalcFiz {
	width: 15vw;
	cursor: pointer;
	background: white;
 }

.CalcPult h3{
	background: #00000000;
 }
 .CalcFiz h3{
	background: #00000000;
 }
 /* #D228{
	display: block;
 } */
 #D229{
	display: none;
	/* animation: Show_Des 2s; */
 }

 .usluga{
	margin: 1vw;
	font-size: 1vw;
	font-weight: bold;
	width: 24vw;
 }

 .usluga input{
	cursor: pointer;
	margin: 0.3vw;
	width: 1.2vw;
	height: 1.2vw;
	vertical-align: middle;
 }
 .usluga label{
	cursor: pointer;
	padding-left: 2vw;
 }
 .usluga h3{
	margin-left: 2.7vw;
 }
 .servicesblocktotal{
	margin: 1vw;
	justify-content: flex-end;
 }
 .servicesblocktotal h3{
	width: 10vw;
	height: 3vw;
 }
  .servicesblocktotal h1{
	font-size: 1.4vw;

 }
 .calcblock1{
	display: flex;
 }
 .calcblock2{
	display: flex;
	
 }

 @keyframes Calc_Show{
	0%{ display: none;
		opacity: 0;
		margin-top: -6vw;
	}
	50%{
		display: block;
	}
	100%{
		opacity: 1;
		margin-top: 0vw;

	}
 }
 

 /* vacations */
 .vacation{
	display: flex;
	margin-bottom: 10vw;
 }
 .vacation2{
	width: 30vw;
	margin-top: 7vw;
	margin-left: 10vw;
 }
 .vacation2 h5{
	color: #FFFFFF;
	font-family: 'Roboto';
	font-style:normal;
	font-weight: lighter;
	font-size: 1.7vw;
 }
 .vacation2 i{
	font-size: 2vw;
	padding: 0.5vw;
 }

 /* prom */
 .prom{
	display: flex;
	justify-content:center;
	margin: 5vw 0vw 10vw 0vw;
 }
 .promsale{
	width: 24vw;
	margin: 0vw 4vw;
	padding: 2vw;
	background-color: white;
	border: 0.3vw solid #566491;
	border-radius: 2vw;
 }
 .promsale h1{
	color: #566491;
	font-size: 1.4vw;
 }
 .promsale h3{
	font-size: 1vw;

	margin: 1vw 0vw;
 }
 .promsale h5{
	font-size: 0.7vw;

	margin-top:0.5vw;
 }
 .promname{
	font-family: 'Roboto';
    font-style: normal;
    font-weight: bolder;
    font-size: 2.4vw;
    color: #FFFFFF;
	text-align: center;
	text-shadow: 0 0 5px rgb(215, 225, 253);
 }